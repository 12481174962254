@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

html, body {
  overflow-x: hidden; 
  margin: 0%;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

