/* Основні стилі сторінки */
.contacts-page {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #82d892, #2d5e33); /* Градієнт фону */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  box-sizing: border-box;
  z-index: -1;
}

.header-с {
  width: 100%;
  background-color: #041c1c; /* Задайте потрібний колір для хедера */
  padding: 20px 0;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

.header-с::before {
  background-color: #00000000;
}

.header-с h1 {
  margin: 0;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Контент */
.contacts-content {
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  padding: 40px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: -30px;

  z-index: -1;
}

h2 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  text-shadow: 1px 1px #ffcc70;
}

p, ul {
  margin: 0 0 15px;
  font-size: 18px;
  color: #555;
}

ul li {
  list-style: none;
}

/* Кольорові кнопки соціальних мереж */
.social-btn {
  display: inline-block;
  padding: 10px 15px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
}

.social-btn.facebook {
  background-color: #3b5998;
}

.social-btn.instagram {
  background-color: #e4405f;
}

.social-btn.twitter {
  background-color: #1da1f2;
}

.social-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Адаптивність */
@media (max-width: 768px) {
  .contacts-content {
    padding: 20px;
    gap: 20px;
  }

  .header h1 {
    font-size: 28px;
  }
}
